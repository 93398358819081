import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer,
  useTheme,
  useMediaQuery,
  ListSubheader,
} from '@material-ui/core';
import AssesmentIcon from '@material-ui/icons/Assessment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExclusionIcon from '@material-ui/icons/EventBusy';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useHistory, useLocation } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import Footer from './footer';
import { useUser } from '../../../context/user';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    root: { zIndex: theme.zIndex.drawer },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

interface DrawerLink {
  label: string;
  path: string;
  icon: JSX.Element;
  disabled?: boolean;
  hasPermission?: boolean;
}

interface Sidebar {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar = ({ isOpen, toggle }: Sidebar) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const shouldRenderSidebar = !['/help', '/profile'].includes(location.pathname);

  const exclusionsItems: DrawerLink[] = [
    {
      label: t('layout.drawer.list.exclusions.report'),
      hasPermission: permissions.includes('SCREEN_EXCLUSIONS'),
      path: '/exclusions',
      icon: <ExclusionIcon />,
    },
  ];

  const mfyItems: DrawerLink[] = [
    {
      label: t('layout.drawer.list.drivethru.report'),
      hasPermission: permissions.includes('SCREEN_MFY_REPORT'),
      path: '/madeforyou/report',
      icon: <FileCopyIcon />,
    },
    {
      label: t('layout.drawer.list.drivethru.charts'),
      path: '/madeforyou/charts',
      hasPermission: permissions.includes('SCREEN_MFY_CHARTS'),
      icon: <AssesmentIcon />,
    },
    {
      label: 'Rankings',
      path: '/madeforyou/rankings',
      hasPermission: permissions.includes('SCREEN_MFY_RANKINGS'),
      icon: <FormatListNumberedIcon />,
    },
  ];

  const dtItems: DrawerLink[] = [
    {
      label: t('layout.drawer.list.drivethru.report'),
      hasPermission: permissions.includes('SCREEN_DT_REPORT'),
      path: '/drivethru/report',
      icon: <FileCopyIcon />,
    },
    {
      label: t('layout.drawer.list.drivethru.charts'),
      hasPermission: permissions.includes('SCREEN_DT_CHARTS'),
      path: '/drivethru/charts',
      icon: <AssesmentIcon />,
    },
    {
      label: 'Rankings',
      hasPermission: permissions.includes('SCREEN_DT_RANKINGS'),
      path: '/drivethru/rankings',
      icon: <FormatListNumberedIcon />,
    },
  ];

  const r2pItems: DrawerLink[] = [
    {
      label: t('layout.drawer.list.drivethru.report'),
      hasPermission: permissions.includes('SCREEN_FC_REPORT'),
      path: '/frontcounter/report',
      icon: <FileCopyIcon />,
    },
    {
      label: t('layout.drawer.list.drivethru.charts'),
      hasPermission: permissions.includes('SCREEN_FC_CHARTS'),
      path: '/frontcounter/charts',
      icon: <AssesmentIcon />,
    },
    {
      label: 'Rankings',
      hasPermission: permissions.includes('SCREEN_FC_RANKINGS'),
      path: '/frontcounter/rankings',
      icon: <FormatListNumberedIcon />,
    },
  ];

  const handleLinkClick = (path: string) => {
    if (isMobile) {
      toggle();
    }
    history.push(path);
  };

  const drawerList = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {permissions.includes('SCREEN_EXCLUSIONS') && (
          <>
            <ListSubheader>{t('layout.drawer.list.exclusions.report').toUpperCase()}</ListSubheader>
            {exclusionsItems.map(
              item =>
                item.hasPermission && (
                  <ListItem
                    key={`drawer-item-${item.label}`}
                    button
                    onClick={() => handleLinkClick(item.path)}
                    selected={location.pathname.includes(item.path)}
                    disabled={item.disabled}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
            )}
          </>
        )}
        <ListSubheader>{'MFY'}</ListSubheader>
        {mfyItems.map(
          item =>
            item.hasPermission && (
              <ListItem
                key={`drawer-item-${item.label}`}
                button
                onClick={() => handleLinkClick(item.path)}
                selected={location.pathname.includes(item.path)}
                disabled={item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
        )}
        <ListSubheader>{'OEPE'}</ListSubheader>
        {dtItems.map(
          item =>
            item.hasPermission && (
              <ListItem
                key={`drawer-item-${item.label}`}
                button
                onClick={() => handleLinkClick(item.path)}
                selected={location.pathname.includes(item.path)}
                disabled={item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
        )}
        <ListSubheader>{'R2P'}</ListSubheader>
        {r2pItems.map(
          item =>
            item.hasPermission && (
              <ListItem
                key={`drawer-item-${item.label}`}
                button
                onClick={() => handleLinkClick(item.path)}
                selected={location.pathname.includes(item.path)}
                disabled={item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
        )}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || '')}
        >
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Flex Tiles" />
        </ListItem>
      </List>
      <Footer />
    </div>
  );

  if (!shouldRenderSidebar) return null;

  return (
    <nav className={classes.drawer} aria-label="sidebar">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isOpen}
          onClose={toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{ zIndex: theme.zIndex.drawer }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
