import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { useReport } from '../../../context/report';
import { useTranslation } from 'react-i18next';
import { useInstitutional } from '../../../context/institutional';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
  })
);

interface CountrySelector {
  noLabel?: boolean;
  value?: string;
  disabled?: boolean;
}

const CountrySelector = ({ noLabel, value, disabled }: CountrySelector) => {
  const { filters, updateFilters } = useReport();
  const { filteredCountries } = useInstitutional();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const disableSelector =
    disabled || filters.division.length === 0 || filteredCountries.length === 1;
  const singleCountrySelection =
    location.pathname.includes('/drivethru/rankings') ||
    location.pathname.includes('/drivethru/charts') ||
    location.pathname.includes('exclusions');

  const renderValue = (selected: string[]) => {
    if (smDown) {
      if ((selected as string[]).length > 1) {
        return `${(selected as string[]).length} ${t('shared.labels.selected')}`;
      } else {
        return t(`shared.labels.${(selected as string[])[0]}`);
      }
    } else {
      return (selected as string[]).map(option => t(`shared.labels.${option}`)).join(', ');
    }
  };

  const handleSelectCountries = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selection = event.target.value as string[];
    updateFilters({
      ...filters,
      countries: selection,
      stores: [],
      regionalManagements: [],
      managements: [],
      supervisions: [],
      regions: [],
      storeAgreements: [],
      franchises: [],
    });
  };

  const handleSelectCountry = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFilters({
      ...filters,
      countries: [event.target.value as string],
      stores: [],
      regionalManagements: [],
      managements: [],
      supervisions: [],
      regions: [],
      storeAgreements: [],
      franchises: [],
    });
  };

  if (singleCountrySelection) {
    return (
      <FormControl className={classes.formControl}>
        {!noLabel && (
          <InputLabel id="country-single-select-label">{t('reports.labels.country')}</InputLabel>
        )}
        <Select
          labelId="country-single-simple-select-label"
          id="country-single-select"
          value={value || filters.countries[0] || ''}
          onChange={handleSelectCountry}
          required
          disabled={disableSelector}
          autoWidth
        >
          {filteredCountries &&
            filteredCountries.map(country => (
              <MenuItem key={country} value={country}>
                {t(`shared.labels.${country}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl className={classes.formControl}>
        {!noLabel && (
          <InputLabel id="country-simple-select-label">{t('reports.labels.countries')}</InputLabel>
        )}
        <Select
          labelId="country-simple-select-label"
          id="country-simple-select"
          value={value ? [value] : filters.countries}
          onChange={handleSelectCountries}
          required
          disabled={disableSelector}
          multiple
          MenuProps={{ getContentAnchorEl: null }}
          autoWidth
          renderValue={selected => renderValue(selected as string[])}
        >
          {filteredCountries &&
            filteredCountries.map(country => (
              <MenuItem key={country} value={country}>
                <Checkbox checked={filters.countries.indexOf(country) > -1} />
                <ListItemText primary={t(`shared.labels.${country}`)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
};

export default CountrySelector;
