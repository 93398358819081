/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext } from 'react';
import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  AppPermission,
  DriveThru,
  FormattedFilters,
  FrontCounter,
  MadeForYou,
  SosResponse,
  Store,
  LabelLanguage,
  FilterLabels,
  SOSExclusions,
} from '../types';
import { useAuthentication } from './authentication';
import { isSameDay } from 'date-fns';

interface ApiContext {
  fetchMadeForYou: (
    filters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<SosResponse<MadeForYou>>;
  fetchDriveThru: (
    filters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<SosResponse<DriveThru>>;
  fetchFrontCounter: (
    filters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<SosResponse<FrontCounter>>;
  fetchMadeForYouRanking: (filters: FormattedFilters) => AxiosPromise<SosResponse<MadeForYou>>;
  fetchDriveThruRanking: (filters: FormattedFilters) => AxiosPromise<SosResponse<DriveThru>>;
  fetchFrontCounterRanking: (filters: FormattedFilters) => AxiosPromise<SosResponse<FrontCounter>>;
  fetchPermissions: () => AxiosPromise<AppPermission[]>;
  fetchOperativeStructure: () => AxiosPromise<Store[]>;

  fetchExclusionsReport: (filters: FormattedFilters) => AxiosPromise<SOSExclusions[]>;
  fetchCreateExclusion: (
    filters: FormattedFilters,
    data: Partial<SOSExclusions>
  ) => AxiosPromise<SOSExclusions>;
  fetchUpdateExclusion: (
    filters: FormattedFilters,
    data: Partial<SOSExclusions>
  ) => AxiosPromise<SOSExclusions>;
  fetchDeleteExclusion: (userUpdate: string, id: string) => AxiosPromise<any>;
}

const initialState: ApiContext = {
  fetchMadeForYou: () => new Promise(() => {}),
  fetchDriveThru: () => new Promise(() => {}),
  fetchFrontCounter: () => new Promise(() => {}),
  fetchMadeForYouRanking: () => new Promise(() => {}),
  fetchDriveThruRanking: () => new Promise(() => {}),
  fetchFrontCounterRanking: () => new Promise(() => {}),
  fetchOperativeStructure: () => new Promise(() => {}),
  fetchPermissions: () => new Promise(() => {}),
  fetchExclusionsReport: () => new Promise(() => {}),
  fetchCreateExclusion: () => new Promise(() => {}),
  fetchUpdateExclusion: () => new Promise(() => {}),
  fetchDeleteExclusion: () => new Promise(() => {}),
};

const ApiContext = createContext(initialState);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuthentication();
  const rsApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/rs`) ||
      'https://api-flex-dev.arcosdorados.net/rs',
  });

  const sosApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/sos`) ||
      'https://api-flex-dev.arcosdorados.net/sos',
    headers: {
      appId: 'sos',
    },
  });

  rsApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'sos',
      };
      return value;
    } else throw Error('token-error');
  });

  sosApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'sos',
      };
      return value;
    } else throw Error('token-error');
  });

  const fetchMadeForYou = (
    filters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ): AxiosPromise<SosResponse<MadeForYou>> => {
    return sosApi.get('/mfy', {
      params: {
        ...filters,
        export: exportRequest,
        labels,
        filterLabels,
      },
    });
  };

  const fetchDriveThru = (
    queryParameters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ): AxiosPromise<SosResponse<DriveThru>> => {
    return sosApi.get('/dt', {
      params: {
        ...queryParameters,
        export: exportRequest,
        labels,
        filterLabels,
      },
    });
  };

  const fetchFrontCounter = (
    queryParameters: FormattedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    return sosApi.get('/fc', {
      params: {
        ...queryParameters,
        export: exportRequest,
        labels,
        filterLabels,
      },
    });
  };

  const fetchMadeForYouRanking = (
    filters: FormattedFilters
  ): AxiosPromise<SosResponse<MadeForYou>> => {
    return sosApi.get('/mfy-rankings', {
      params: {
        ...filters,
      },
    });
  };

  const fetchDriveThruRanking = (
    queryParameters: FormattedFilters
  ): AxiosPromise<SosResponse<DriveThru>> => {
    return sosApi.get('/dt-rankings', {
      params: {
        ...queryParameters,
      },
    });
  };

  const fetchFrontCounterRanking = (queryParameters: FormattedFilters) => {
    return sosApi.get('/fc-rankings', {
      params: {
        ...queryParameters,
      },
    });
  };

  const fetchOperativeStructure = (): AxiosPromise<Store[]> => rsApi.get('/op-structures');

  const fetchPermissions = (): AxiosPromise<AppPermission[]> => rsApi.get('/permissions');

  const fetchExclusionsReport = (filters: FormattedFilters): AxiosPromise<SOSExclusions[]> => {
    const { country, storeAcronym, service, fromDate, toDate, frequency } = filters;
    const filteredparams = { country, storeAcronym, service, fromDate, toDate };
    const inSameDay = toDate && isSameDay(new Date(fromDate), new Date(toDate));
    return sosApi.get('/get-excl-rule', {
      params: {
        ...filteredparams,
        frequency: inSameDay ? frequency : undefined,
      },
    });
  };

  const fetchCreateExclusion = (
    filters: FormattedFilters,
    data: Partial<SOSExclusions>
  ): AxiosPromise<SOSExclusions> => {
    const { country, storeAcronym, service, fromDate, toDate, frequency } = filters;
    const filteredparams = { country, storeAcronym, service, fromDate, toDate };
    const inSameDay = toDate && isSameDay(new Date(fromDate), new Date(toDate));
    return sosApi.post('/create-excl-rule', {
      body: {
        ...filteredparams,
        ...data,
        frequency: inSameDay ? frequency : undefined,
      },
    });
  };

  const fetchUpdateExclusion = (
    filters: FormattedFilters,
    data: Partial<SOSExclusions>
  ): AxiosPromise<SOSExclusions> => {
    const { country, storeAcronym, service, fromDate, toDate, frequency } = filters;
    const filteredparams = { country, storeAcronym, service, fromDate, toDate };
    const inSameDay = toDate && isSameDay(new Date(fromDate), new Date(toDate));
    const id = `${data.storePK}_${data.fromDate}`;
    return sosApi.put(`/update-excl-rule/${id}`, {
      body: {
        ...filteredparams,
        ...data,
        toDate,
        frequency: inSameDay ? frequency : undefined,
      },
    });
  };

  const fetchDeleteExclusion = (userUpdate: string, id: string): AxiosPromise<SOSExclusions> => {
    return sosApi.delete(`/delete-excl-rule/${id}`, {
      params: {
        userUpdate,
      },
    });
  };

  return (
    <ApiContext.Provider
      value={{
        fetchMadeForYou,
        fetchDriveThru,
        fetchFrontCounter,
        fetchMadeForYouRanking,
        fetchDriveThruRanking,
        fetchFrontCounterRanking,
        fetchOperativeStructure,
        fetchPermissions,
        fetchExclusionsReport,
        fetchCreateExclusion,
        fetchUpdateExclusion,
        fetchDeleteExclusion,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
