import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  DialogContent,
  List,
  ListSubheader,
  DialogActions,
  Tooltip,
  Grid,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import SimpleSelector from '../../selectors/simple';
import { useReport } from '../../../context/report';
import { STORE_AGREEMENTS } from '../../../utils/constants';
import {
  Franchise,
  Management,
  Region,
  RegionalManagement,
  Store,
  StoreAgreement,
  Supervision,
} from '../../../types';
import DateRangePicker from '../DateRangePicker';
import SegmentSelector from '../SegmentSelector';
import AutocompleteSelector from '../../selectors/autocomplete';
import { useInstitutional } from '../../../context/institutional';
import { AutocompleteRenderGroupParams } from '@material-ui/lab';
import { useUser } from '../../../context/user';
import DivisionSelector from '../selectors/DivisionSelector';
import GroupSelector from '../selectors/GroupSelector';
import FrequencySelector from '../selectors/FrequencySelector';
import { CloudDownload } from '@material-ui/icons';
import { isSameDay } from 'date-fns';
import DisableToDateCheckbox from '../selectors/DisableToDateCheckbox';
import ServiceSelector from '../selectors/ServiceSelector';

interface FiltersDialog {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  fetchData: () => Promise<void>;
  requestExport?: () => Promise<void>;
  setOpenExclusions?: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    subtitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    resetButton: {
      margin: theme.spacing(2),
    },
  })
);

const FiltersDialog = ({ open, setOpen, fetchData, requestExport }: FiltersDialog) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { permissions } = useUser();
  const { filters, updateFilters } = useReport();
  const {
    filteredCountries,
    filteredFranchises,
    filteredManagements,
    filteredRegionalManagements,
    filteredRegions,
    filteredStores,
    filteredSupervisions,
    loading: loadingInstitutionalData,
  } = useInstitutional();
  const [dateError, setDateError] = React.useState(false);

  const isGroupable = /\/*\/report/.test(location.pathname);
  const inExclusions = location.pathname.includes('exclusions');
  const canFilterOperativeStructure = permissions.includes('FILTER_OPSTRUCTURE') && !inExclusions;
  const canExportReport =
    location.pathname.includes('report') && permissions.includes('EXPORT_REPORT');
  const inDetailed = location.pathname.includes('detailed');
  const inRanking = location.pathname.includes('rankings');
  const inCharts = location.pathname.includes('charts');
  const missingDivision = filters.division.length < 1;
  const missingYear = filters.year === '' || filters.year === undefined;
  const missingCountry = filters.countries.length === 0;
  const missingStore = filters.stores.length === 0;
  const missingGrouping = filters.groupBy.length === 0;
  const loading = loadingInstitutionalData;
  const disableFetch =
    ((missingDivision || missingGrouping) && !inRanking) ||
    ((inRanking || inCharts || inExclusions) && missingCountry) ||
    missingYear ||
    (inExclusions && missingStore) ||
    dateError ||
    loading;

  const inSameDay = isSameDay(filters.fromDate, filters.toDate);
  const showSegments = !(inExclusions && !inSameDay);

  const handleClose = () => setOpen(false);

  const handleApply = async () => {
    handleClose();
    await fetchData();
  };

  const handleSelectCountries = (selection: string | string[]) => {
    updateFilters({
      ...filters,
      countries: selection as string[],
      stores: [],
      regionalManagements: [],
      managements: [],
      supervisions: [],
      regions: [],
      storeAgreements: [],
      franchises: [],
    });
  };

  const handleSelectRegionalManagement = (selection: RegionalManagement[]) => {
    updateFilters({
      ...filters,
      regionalManagements: selection,
      regions: filters.regions.filter(r => selection.map(s => s.id).includes(r.regionalMgmtId)),
      managements: filters.managements.filter(m =>
        selection.map(s => s.id).includes(m.regionalMgmtId)
      ),
      supervisions: filters.supervisions.filter(s =>
        selection.map(rm => rm.id).includes(s.regionalMgmtId)
      ),
      stores: [],
    });
  };

  const handleSelectFranchises = (selection: Franchise[]) => {
    updateFilters({ franchises: selection, stores: [] });
  };

  const handleSelectRegion = (selection: Region[]) => {
    updateFilters({
      ...filters,
      regions: selection,
      managements: filters.managements.filter(m => selection.map(s => s.id).includes(m.regionId)),
      supervisions: filters.supervisions.filter(s => selection.map(r => r.id).includes(s.regionId)),
      stores: [],
    });
  };

  const handleSelectManagements = (selection: Management[]) => {
    updateFilters({
      ...filters,
      managements: selection,
      supervisions: filters.supervisions.filter(s => selection.map(m => m.id).includes(s.mgmtId)),
      stores: [],
    });
  };

  const handleSelectSupervisions = (selection: Supervision[]) => {
    updateFilters({
      ...filters,
      supervisions: selection,
      stores: [],
    });
  };

  const handleSelectStoreAgreements = (selection: StoreAgreement[]) => {
    updateFilters({ storeAgreements: selection, stores: [] });
  };

  const renderCountryGroup = (element: AutocompleteRenderGroupParams) => (
    <List
      key={element.key}
      subheader={<ListSubheader>{t(`shared.labels.${element.group}`)}</ListSubheader>}
    >
      {element.children}
    </List>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullScreen keepMounted={true}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('dialogs.filters.title')}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleApply} disabled={disableFetch}>
            {inExclusions ? t('reports.labels.search') : t('navigation.buttons.apply')}
          </Button>
          {canExportReport && (
            <Tooltip title={t('reports.labels.saveInOneDrive').toString()}>
              <span>
                <Button onClick={requestExport} disabled={disableFetch} color="inherit">
                  <CloudDownload />
                </Button>
              </span>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container item xs={12} direction="column">
          <Grid container item xs={12} direction="row">
            {isGroupable && !inExclusions && <GroupSelector />}
            {isGroupable && !inExclusions && filters.groupBy.includes('time') && (
              <FrequencySelector />
            )}
            {inExclusions && (
              <>
                <ServiceSelector />
                <DisableToDateCheckbox />
              </>
            )}
          </Grid>

          <Grid container item xs={12} direction="row">
            {inDetailed && (
              <Typography className={classes.subtitle} variant="subtitle2">
                {t('shared.labels.businessDay')}
              </Typography>
            )}
            <DivisionSelector />
            <SimpleSelector
              label={t('reports.labels.country')}
              options={filteredCountries}
              value={filters.countries}
              handleSelect={handleSelectCountries}
              optionFormatter={option => t(`shared.labels.${option}`)}
              disabled={
                filters.division.length === 0 ||
                (filteredCountries.length < 2 && filters.countries.length > 0)
              }
              multiple={!inExclusions}
            />
            <AutocompleteSelector
              label={t('reports.labels.store')}
              options={filteredStores}
              value={filters.stores}
              handleSelect={selection => updateFilters({ stores: selection as Store[] })}
              groupBy={filters.countries.length > 1 ? option => option.country : undefined}
              renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
              renderOption={option => option.storeAcronym}
              disabled={
                filters.division.length === 0 ||
                (filteredStores.length < 2 && filters.stores.length > 0)
              }
            />
            {(inDetailed || inExclusions) && (
              <DateRangePicker error={dateError} setError={setDateError} />
            )}
            {showSegments && <SegmentSelector />}
          </Grid>
          <Grid container item xs={12} direction="row">
            {canFilterOperativeStructure && (
              <>
                <Typography className={classes.subtitle} variant="subtitle2">
                  {t('shared.labels.operativeStructure')}
                </Typography>

                <AutocompleteSelector
                  label={t('reports.labels.regionalManagement')}
                  options={filteredRegionalManagements}
                  value={filters.regionalManagements}
                  handleSelect={selection =>
                    handleSelectRegionalManagement(selection as RegionalManagement[])
                  }
                  groupBy={filters.countries.length > 1 ? option => option.country : undefined}
                  renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
                  renderOption={option => option.label}
                  disabled={missingDivision}
                  fullWidth
                />
                <AutocompleteSelector
                  label={t('reports.labels.region')}
                  options={filteredRegions}
                  value={filters.regions}
                  handleSelect={selection => handleSelectRegion(selection as Region[])}
                  groupBy={filters.countries.length > 1 ? option => option.country : undefined}
                  renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
                  renderOption={option => option.label}
                  disabled={missingDivision}
                  fullWidth
                />
                <AutocompleteSelector
                  label={t('reports.labels.management')}
                  options={filteredManagements}
                  value={filters.managements}
                  handleSelect={selection => handleSelectManagements(selection as Management[])}
                  groupBy={filters.countries.length > 1 ? option => option.country : undefined}
                  renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
                  renderOption={option => option.label}
                  disabled={missingDivision}
                  fullWidth
                />
                <AutocompleteSelector
                  label={t('reports.labels.supervision')}
                  options={filteredSupervisions}
                  value={filters.supervisions}
                  handleSelect={selection => handleSelectSupervisions(selection as Supervision[])}
                  groupBy={filters.countries.length > 1 ? option => option.country : undefined}
                  renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
                  renderOption={option => option.label}
                  disabled={missingDivision}
                  fullWidth
                />
                <Typography className={classes.subtitle} variant="subtitle2">
                  {t('toolbar.labels.companyFilters')}
                </Typography>
                <SimpleSelector
                  label={t('reports.labels.storeType')}
                  options={STORE_AGREEMENTS}
                  value={filters.storeAgreements}
                  handleSelect={selection =>
                    handleSelectStoreAgreements(selection as StoreAgreement[])
                  }
                  optionFormatter={option => t(`shared.labels.storeAgreement.${option}`)}
                  fullWidth
                  disabled={missingDivision}
                  multiple
                />
                <AutocompleteSelector
                  label={t('reports.labels.franchises')}
                  options={filteredFranchises}
                  value={filters.franchises}
                  handleSelect={handleSelectFranchises}
                  groupBy={filters.countries.length > 1 ? option => option.country : undefined}
                  renderGroup={filters.countries.length > 1 ? renderCountryGroup : undefined}
                  renderOption={option => option.label}
                  disabled={missingDivision}
                  fullWidth
                />
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" className={classes.resetButton} onClick={handleClose}>
          {t('shared.buttons.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FiltersDialog;
